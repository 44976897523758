<template>
  <div class="">
    <v-subtitle
      zh="常见问题"
      en="Q&A"
      type="mini"
    />
    <v-problems :data="problems" />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vProblems from './problems.vue'
export default {
  components: {
    vSubtitle,
    vProblems
  },
  data () {
    return {
      problems: [
        {
          id: 0,
          q: '驱动可以正常识别到证书，但是登录系统提示：没有找到数字证书？',
          a: '覆盖安装系统登录界面的数字证书驱动，然后重新拔插一下证书即可。'
        },
        {
          id: 1,
          q: '证书登录主体共享平台提示：CA互认失败？',
          a: '一般是由于电脑上同时运行着多把证书或者除证书以外的其它硬件介质造成的，可建议用户只保留一把数字证书，然后去登录系统。'
        },
        {
          id: 2,
          q: '在pdf离线盖章工具里面签章的时候提示：没有图片或图片已过期？',
          a: '这种情况一般是办理证书的时候没有勾选签章业务。'
        },
        {
          id: 3,
          q: '文锐证书收费标准及适用范围？',
          a: '收费标准:<br/>电子印章—100元/个<br/>数字证书开户费—100元/户<br/>储存介质（UKEY）—150元/个（无限期使用）<br/>数字证书更新服务费—200元/户<br/>适用范围：甘肃省15个公共资源交易中心招投标平台的互认通用'
        },
        {
          id: 4,
          q: '文锐证书的办理流程及所需资料？',
          a: '办理流程:<br/>&emsp;&emsp;办理地址：<a href="http://www.jian-yi.com" title="点击跳转" target="_blank" rel="noopener noreferrer">http://www.jian-yi.com</a>，请使用主体平台注册已认证的账号登陆至系统，按照您的需要选择办理类型在线提交订单即可，具体办理操作请参考简易网(<a href="http://www.jian-yi.com/" title="点击跳转" target="_blank" rel="noopener noreferrer">http://www.jian-yi.com/</a>)证书办理操作手册；<br/>所需资料:<br/>1、经办人身份证正反面加盖企业鲜章；<br/>2、授权委托书（请下载模板完善后盖鲜章上传）；<br/>3：责任书（ 请下载模板完善后盖鲜章上传）；'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>

</style>
