<template>
  <div class="projects">
    <div
      class="projects-item"
      v-for="item,index in data"
      :key="index"
    >
      <div class="projects-img">
        <img
          :src="item.img"
          :alt="item.title"
        >
      </div>
      <div class="projects-title">
        {{ item.title }}
      </div>
      <div class="projects-content">
        {{ item.content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.projects {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  &-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 260px;
    height: 220px;
    padding: 10px 10px;
    box-sizing: border-box;
    background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    box-shadow: 0px 1px 9px 1px rgba(0,0,0,0.12);
    border: 3px solid #ffffff;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 2px 2px 0px rgba(73, 120, 250, 0.19);
    }
  }
  &-img {
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100px;
    }
  }
  &-title {
    font-size: 20px;
    color: #000000;
    margin-bottom: 20px;
  }
  &-content {
    font-size: 12px;
    color: #8087ac;
    text-align: center;
    white-space: nowrap;
  }
}
</style>
