<template>
  <div class="customerService">
    <v-subtitle
      zh="客服电话及各地地址"
      en="Customer service"
      type="mini"
    />
    <div class="customerService-main">
      <v-address
        :data="addressList"
        :active-index.sync="activeIndex"
        @toClickactiveIndex="toClickactiveIndex"
      />
      <!-- <v-map :map-url="addressList[activeIndex].coordinate" /> -->
      <v-amap :current-location-index="activeIndex" />
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vAddress from './address.vue'
// import vMap from './map.vue'
import vAmap from './vamap.vue'
export default {
  components: {
    vSubtitle,
    vAddress,
    vAmap
  },
  data () {
    return {
      addressList: [
        {
          id: 0,
          add: '甘肃省公共资源交易中心',
          coordinate: '/static/img/serviceSupport/gs-map.png' // 经纬坐标
        },
        {
          id: 1,
          add: '嘉峪关市公共资源交易中心',
          coordinate: '/static/img/serviceSupport/jyg-map.png' // 经纬坐标
        },
        {
          id: 2,
          add: '张掖市公共资源交易中心',
          coordinate: '/static/img/serviceSupport/zy-map.png' // 经纬坐标
        },
        {
          id: 3,
          add: '天水市公共资源交易中心',
          coordinate: '/static/img/serviceSupport/ts-map.png' // 经纬坐标
        },
        {
          id: 4,
          add: '平凉市公共资源交易中心',
          coordinate: '/static/img/serviceSupport/pl-map.png' // 经纬坐标
        },
        {
          id: 5,
          add: '庆阳市公共资源交易中心',
          coordinate: '/static/img/serviceSupport/qy-map.png' // 经纬坐标
        },
        {
          id: 6,
          add: '陇南市公共资源交易中心',
          coordinate: '/static/img/serviceSupport/ln-map.png' // 经纬坐标
        },
        {
          id: 7,
          add: '临夏州公共资源交易中心',
          coordinate: '/static/img/serviceSupport/lx-map.png' // 经纬坐标
        }
      ],
      activeIndex: 0
    }
  },
  methods: {
    toClickactiveIndex (id) {
      console.log(id, '父亲id')
      this.activeIndex = id
    }
  }
}
</script>

<style lang="less" scoped>
.customerService {
  &-main {
    display: flex;
    justify-content: space-between;
    height: 700px;
    box-shadow: 0px 1px 9px 1px rgba(0,0,0,0.12);
    border-radius: 4px;
    overflow: hidden;
  }
}
</style>
