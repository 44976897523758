<template>
  <div class="service-support">
    <v-banner-box :data="swiperData">
      <template slot="button">
        <p />
      </template>
    </v-banner-box>
    <div class="advet">
      <a
        href="http://www.jian-yi.com/"
        target="_blank"
      ><img src="/static/img/serviceSupport/advet.png"></a>
    </div>
    <!-- <v-search
      placeholder="请输入您的问题"
      @search="search"
      :input-value.sync="searchVal"
    /> -->
    <v-nav-bar-type />
    <v-q-a />
    <v-customer-service />
  </div>
</template>

<script>
// NOTE :  服务与支持
import vBannerBox from '@/components/banner/bannerBox.vue'
// import vSearch from '@/components/searchInput/index.vue'
import vNavBarType from './components/navBarType/index.vue'
import vQA from './components/Q&A/index.vue'
import vCustomerService from './components/customerService/index.vue'

export default {
  components: {
    vBannerBox,
    // vSearch,
    vNavBarType,
    vQA,
    vCustomerService
  },
  watch: {},
  data () {
    return {
      swiperData: [],
      // 搜索框内容
      searchVal: ''
    }
  },
  created () {},
  methods: {
    search (val) {
      console.log(val)
    },
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['服务与支持']
      }
      this.swiperData = []
      this.$api
        .getBannerInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.swiperData = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    }
  },
  mounted () {
    this.getSwiperData()
  }
}
</script>

<style lang="less" scoped>
.service-support {
  .advet {
    text-align: center;
    position: relative;
    margin-top: -50px;
    img {
      width: 1010px;
      height: 175px;
    }
  }
}
</style>
