<template>
  <div class="serviceSupport-nav">
    <v-projects
      :data="projects"
    />
    <!-- <v-qa-type
      :data="QANav"
      :active-index.sync="activeIndex"
    /> -->
  </div>
</template>

<script>
import vProjects from './projects.vue'
// import vQaType from './QAType.vue'
export default {
  props: {},
  components: {
    vProjects
    // vQaType
  },
  data () {
    return {
      projects: [
        {
          img: '/static/img/serviceSupport/a-1.png',
          title: '管理员办理证书',
          content: '签章事项、试签工作、邮寄证书、售后服务'
        },
        {
          img: '/static/img/serviceSupport/a-2.png',
          title: '售后保障',
          content: '签章事项、试签工作、售后服务'
        },
        {
          img: '/static/img/serviceSupport/a-3.png',
          title: '简易网证书/酒钢证书',
          content: '签章事项、试签工作、邮寄证书、售后服务'
        },
        {
          img: '/static/img/serviceSupport/a-4.png',
          title: '财政厅证书',
          content: '签章事项、试签工作、邮寄证书、售后服务'
        }
      ],
      QANav: [
        {
          id: '1',
          name: '办理流程'
        },
        {
          id: '2',
          name: '收费标准'
        },
        {
          id: '3',
          name: '适用范围'
        },
        {
          id: '4',
          name: '证书退款'
        },
        {
          id: '5',
          name: '证书签证'
        },
        {
          id: '6',
          name: '所需材料'
        }
      ],
      activeIndex: 0
    }
  }
}
</script>

<style lang="less" scoped>
.serviceSupport-nav{
  margin-top: 50px;
}
</style>
