<template>
  <div class="address">
    <div
      class="address-item"
      :class="{on: index === activeIndex}"
      v-for="(item, index) in data"
      :key="index"
      @click="toClickactiveIndex(item.id)"
    >
      <!-- @click="$emit('update:activeIndex', index)" -->
      {{ item.add }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  methods: {
    toClickactiveIndex (id) {
      this.$emit('toClickactiveIndex', id)
    }
  }
}
</script>

<style lang="less" scoped>
.address {
  width: 265px;
  height: 700px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
  border: 3px solid #ffffff;
  &-item {
    position: relative;
    height: 70px;
    line-height: 70px;
    text-align: center;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.16);
    transition: cubic-bezier(0.39, 0.575, 0.565, 1) 0.1s;
    color: #6D7281;
    cursor: pointer;
    overflow: hidden;
    &:hover, &.on {
      background: #ffffff;
      box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.16);
      color: #3060E4;
      &::after{
        opacity: 1;
      }
    }
    &::after{
        content: "";
        position: absolute;
        top: -10px;
        left: -10px;
        height: 20px;
        width: 20px;
        background: linear-gradient(180deg, #91ADFF 0%, #0949F9 100%);
        transform: rotate(45deg);
        opacity: 0;
        transition: cubic-bezier(0.39, 0.575, 0.565, 1) .1s;
    }
  }
}
</style>
