<template>
  <div class="problems">
    <div
      class="problems-item"
      v-for="(item, index) in data"
      :key="index"
    >
      <div class="problems-item-q">
        <div class="icon">
          <img src="/static/img/serviceSupport/qa.png">
        </div>
        <div class="title">
          {{ item.q }}
        </div>
      </div>
      <div class="problems-item-a">
        <div
          class="content"
          v-html="item.a"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="less" scoped>
.problems {
  background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
  box-shadow: 0px 1px 9px 1px rgba(0,0,0,0.12);
  border: 3px solid #ffffff;
  padding: 50px 40px 0;
  box-sizing: border-box;
  margin-bottom: 80px;
  border-radius: 8px;
  &-item {
    padding: 37px 0 38px;
    &:not(:last-child){
        border-bottom: 1px solid #7070703d;
    }
    &-q {
      display: flex;
      align-items: center;

      font-size: 20px;
      color: #000;
      font-weight: bold;
      margin-bottom: 22px;
      .icon{
        height: 22px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 100%;
        }
      }
    }
    &-a {
      font-size: 20px;
      color: #6d7281;
      padding-left: 50px;
      line-height: 24px;
    }
  }
}
</style>
