<template>
  <div class="asdflex">
    <div id="container" />
  </div>
</template>

<script>
export default {
  props: {
    currentLocationIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      map: null,
      infoWindow: null,
      locations: [
        {
          position: [103.89259, 36.0512],
          title: '甘肃省公告资源交易中心',
          content: [
            '地址：甘肃省兰州市城关区雁兴路68号',
            '电话：010-64733333',
            '工作时间：早08:30-12:00，14:30-18:00'
          ].join('<br/>')
        },
        {
          position: [103.214806, 35.595459],
          title: '嘉峪关市公共资源交易中心',
          content: [
            '地址：临夏回族自治州临夏市中心广场青年路2号临夏州公共资源交易中心',
            '电话：0931-4267890',
            '工作时间：早08:30-12:00，14:30-18:00'
          ].join('<br/>')
        },
        {
          position: [100.478058, 38.929764],
          title: '张掖市公共资源交易中心',
          content: [
            '地址：甘肃省张掖市日州区丹雷东路18号',
            '电话：0931-4267890',
            '工作时间：早08:30-12:00，14:30-18:00'
          ].join('<br/>')
        },
        {
          position: [105.723602, 34.583782],
          title: '天水市公共资源交易中心',
          content: [
            '地址：甘肃省天水市秦州区建设路185号原政务大',
            '电话：0931-4267890',
            '工作时间：早08:30-12:00，14:30-18:00'
          ].join('<br/>')
        },
        {
          position: [106.673691, 35.553741],
          title: '平凉市公共资源交易中心',
          content: [
            '地址：平凉市兴北路世纪花园B1区东门',
            '电话：0931-4267890',
            '工作时间：早08:30-12:00，14:30-18:00'
          ].join('<br/>')
        },
        {
          position: [107.6418, 35.70395],
          title: '庆阳市公共资源交易中心',
          content: [
            '地址：庆阳市西峰区朔州西路7号',
            '电话：0931-4267890',
            '工作时间：早08:30-12:00，14:30-18:00'
          ].join('<br/>')
        },
        {
          position: [104.960922, 33.370739],
          title: '陇南市公共资源交易中心',
          content: [
            '地址：陇南市行政中心5号楼(环保大厦2楼)',
            '电话：0931-4267890',
            '工作时间：早08:30-12:00，14:30-18:00'
          ].join('<br/>')
        },
        {
          position: [103.21422, 35.59592],
          title: '临夏州公共资源交易中心',
          content: [
            '地址：甘肃省临夏回族自治州临夏市中心广场青年路2号',
            '电话：0931-4267890',
            '工作时间：早08:30-12:00，14:30-18:00'
          ].join('<br/>')
        }
      ]
    }
  },
  mounted () {
    this.loadAMap(() => {
      this.initMap()
    })
  },
  watch: {
    currentLocationIndex (newIndex) {
      // 当外部 prop 改变时，更新地图
      const location = this.locations[newIndex]
      this.addMarker(location)
    }
  },
  methods: {
    loadAMap (callback) {
      if (window.AMap) {
        callback()
      } else {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src =
          'https://webapi.amap.com/maps?v=1.4.15&key=7349890ca36fae2806c55fb71ab7ca44&callback=initAMap'
        script.async = true
        script.onerror = () => {
          console.error('Failed to load AMap library.')
        }
        window.initAMap = callback
        document.body.appendChild(script)
      }
    },
    initMap () {
      this.map = new window.AMap.Map('container', {
        resizeEnable: true,
        center: this.locations[this.currentLocationIndex].position,
        zoom: 30
      })
      this.addMarker(this.locations[this.currentLocationIndex])
    },
    addMarker (location) {
      this.map.clearMap()
      const marker = new window.AMap.Marker({
        map: this.map,
        position: location.position
      })
      this.infoWindow = new window.AMap.InfoWindow({
        isCustom: true,
        content: this.createInfoWindow(location.title, location.content),
        offset: new window.AMap.Pixel(16, -45)
      })
      this.infoWindow.open(this.map, marker.getPosition())
      this.map.setCenter(location.position)
    },
    createInfoWindow (title, content) {
      const info = document.createElement('div')
      info.className = 'custom-info input-card content-window-card'
      const infoTemplate = `
        <div class="info-top">
          <div>${title}</div>
        </div>
        <div class="info-middle">
          ${content}
        </div>
        <div class="info-bottom">
          <img src="https://webapi.amap.com/images/sharp.png">
        </div>
      `
      info.innerHTML = infoTemplate
      return info
    }
  }
}
</script>

<style lang="less">
.asdflex {
  flex: 1;

  #container {
    height: 100%;
    width: 100%;
  }

  .location-list {
    margin-top: 20px;
    text-align: center;
  }

  .location-list ul {
    list-style: none;
    padding: 0;
  }

  .location-list li {
    cursor: pointer;
    padding: 10px;
    background-color: #f0f0f0;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  .location-list li:hover {
    background-color: #ddd;
  }

  .content-window-card p {
    height: 2rem;
  }

  .custom-info {
    border-radius: 13px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
  }

  div.info-top {
    position: relative;
    background: none repeat scroll 0 0 #fdfdfd;
    border-bottom: 2px solid #4978f9;
    text-align: center;
    border-radius: 18px 18px 0 0;
  }

  div.info-top div {
    display: inline-block;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    line-height: 31px;
    padding: 0 10px;
  }

  div.info-middle {
    font-size: 12px;
    padding: 18px;
    line-height: 20px;
    border-radius: 0 0 18px 18px;
    background-color: white;
  }

  div.info-bottom {
    height: 0px;
    width: 100%;
    clear: both;
    text-align: center;
  }

  div.info-bottom img {
    position: relative;
    z-index: 104;
  }

  span {
    margin-left: 5px;
    font-size: 11px;
  }

  .info-middle img {
    float: left;
    margin-right: 6px;
  }

  /* 隐藏关闭按钮 */
  .info-top img {
    display: none;
  }

  /* 点计 */
  .clikswitch {
    display: flex;
    margin-top: 20px;
  }

  .clikswitch .diz {
    width: 50px;
    height: 20px;
    border: 1px solid tomato;
    text-align: center;
    margin-right: 5px;
    cursor: pointer;
  }
}
</style>
